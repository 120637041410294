@use 'fonts/fonts' as *;
@use 'breakpoints' as *;
@use 'bootstrap-add-ins' as *;
@use 'colors' as *;

// Library imports
@import 'lib/bootstrap-grid.min.css';
@import 'lib/bootstrap-reboot.min.css';
@import 'lib/hamburger';
@import 'lib/tooltipster.bundle.min.css';
@import 'lib/tooltipster-sideTip-borderless.min.css';

html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    --primary-color: #15a9e8;
    --primary-color-rgb: 21, 169, 232;
    --primary-color-lighter: #0adfff;
    --primary-color-darker: #{darken(#15a9e8, 5%)};
    --primary-color-dark: #206d8d;
    --primary-gradient-halfway: #0ecbf7;
    --font-light-grey: #c1c1c1;
    --font-medium-grey: #a1a1a1;
    --font-medium-grey-darker: #{darken(#a1a1a1, 3%)};
    --font-dark-grey: #5c5c5c;
    --dark-blue: #525d76;
    // Dark Mode
    --dark-grey: #464d5d;
    --dark-grey-rgb: 70, 77, 93;
    --dark-primary-color: #515c6d;
    --button-dark-mode: #4d5466;
    --button-hover-dark-mode: #586074;
    --divider-on-dark-grey-card: #51596c;

    --selection-color: #c7edfc;
    --light-element-background-color: #f4f6fa;
    --light-element-hover-color: #e6eaf2;
    --light-element-helper-icon-color: #b0c0d4;
    --short-payment-color: #bc52cc;
    --success-color: #00dc3a;
    --error-color: #da0000;
    --error-color-darker: #{darken(#da0000, 3%)};
    --info-note-button-font-color: #12a1e2;
    --info-note-button-background-color: rgb(var(--primary-color-rgb), 5%);
    --info-note-button-background-color-hover: rgb(var(--primary-color-rgb), 10%);
}

body {
    font-family: 'Source Sans Pro', system-ui, sans-serif;
    color: #525c65;
    line-height: 1.5;
    margin: 0;
}

section {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (max-width: $md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

img {
    max-width: 100%;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

a {
    color: #15a9e8;

    &:hover {
        text-decoration: none;
        color: #15a9e8;
    }

    &.white-link {
        text-decoration: underline;
        color: white;
    }
}

h1,
h2,
h3,
h4,
h5 {
    color: #333333;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.2;
}

h1 {
    font-size: 2.4rem;
    @media (min-width: $md) {
        font-size: 3rem;
    }
}

h2 {
    font-size: 2rem;
}

h3.additional-headline {
    text-transform: uppercase;
    color: #15a9e8;
    font-size: 1rem;
    font-weight: 600;
}

hr {
    border: 0;
    border-top: 2px solid #15a9e8;
    width: 40px;
    margin-left: 0;
    margin-right: auto;
    @media (max-width: $md) {
        margin-left: auto;
    }
}

.label {
    color: #a1a1a1;
    transition: color 0.2s ease;

    &:hover {
        color: #a1a1a1;
    }
}

// Heading styles from aX app
.h1-ax-app {
    font-size: 18pt;
    font-weight: 200;
}

.h2-ax-app,
.h3-ax-app,
.h4-ax-app {
    text-transform: uppercase;
    font-weight: 600;
}

.h2-ax-app {
    font-size: 1.2em;
}

.h3-ax-app {
    font-size: 1em;
}

.h4-ax-app {
    font-size: 10pt;
}

.h1-ax-app,
.h2-ax-app,
.h3-ax-app,
.h4-ax-app {
    color: $font-dark-grey;
}

.label-ax-app {
    font-size: 10pt;
    color: $font-medium-grey;

    &.warning {
        color: #ffae00;
    }

    &.error {
        color: #fa0000;
    }
}

//*****************************************************************************
//  Bootstrap Overwrites
//****************************************************************************/
.container {
    @media (max-width: $md) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Bootstrap Overwrites
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  General
//****************************************************************************/
.button-solid {
    padding: 10px 25px;
    background-color: #15a9e8;
    color: white;
    font-weight: 600;
    margin-left: 5px;
    margin-right: 5px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;

    &:hover {
        color: white; // Overwrite standard link behavior
        background-color: darken(#15a9e8, 3%);
        box-shadow:
            0 4px 8px rgba(0, 0, 0, 0.22),
            0 8px 16px rgba(0, 0, 0, 0.15);
    }

    &.light {
        background: white;
        color: #15a9e8;
    }

    &.secondary {
        color: #15a9e8;
        border: 2px solid #15a9e8;
        background: white;
    }

    &.ghost-button-ax-app {
        position: relative;
        color: white;
        background: none;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 2px solid white;
            border-radius: 3px;
        }
    }

    &.flat {
        background: none;
        color: #a1a1a1;

        &:not(:hover) {
            box-shadow: none;
        }
    }
}

.help-indicator {
    border-radius: 50%;
    border: 1px solid #c1c1c1;
    color: #c1c1c1;
    width: 1rem;
    height: 1rem;
    min-width: 17px;
    min-height: 17px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: help;
    margin-left: 5px;
    font-size: 0.8rem;
    font-weight: 600;

    &.help-indicator-small {
        min-width: 15px;
        min-height: 15px;
        margin-left: 3px;
    }
}

.white-space-nowrap {
    white-space: nowrap;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END General
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Header
//****************************************************************************/
header {
    position: relative;
    z-index: 999;
    width: 100%;
    padding: 0 40px;
}

#header-logo-container {
    width: 180px;
}

#header-logo {
    display: none;
    width: 180px;
    @media (min-width: $lg) {
        display: unset;
    }
}

#mobile-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    padding: 15px 20px;
    background: rgba(white, 0.8);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 5px 0 rgba(#000, 0.05);

    i {
        transition: opacity 0.2s ease;
    }

    #navigation-toggler-close-icon {
        display: none;
    }

    .hamburger {
        flex: 1 1 50%;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        width: 20px;
        height: 2px;
        background-color: #333;
    }
}

#mobile-navigation-autoixpert-logo {
    height: 30px;
}

#mobile-navigation-signup-button-container {
    text-align: right;
    flex: 1 1 50%;

    a {
        font-weight: bold;
    }
}

#header-navigation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
}

#header-navigation-row {
    @media (max-width: $lg) {
        // It goes off and on screen when the user hits the menu button
        position: fixed;
        z-index: 5; // Must be lower than the menu icon
        left: 0;
        top: 0;
        width: 70vw;
        padding: 44px 35px 0;
        background: white;
        height: 100vh;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
        transform: translate3d(-100%, 0, 0);
        transition: transform 0.3s ease;
        &.shown {
            transform: translate3d(0, 0, 0);
        }

        & .button-solid {
            margin-top: 40px;
        }
    }
}

#header-navigation {
    margin-top: 50px;
    @media (min-width: $md) {
        margin-top: unset;
    }
}

.header-navigation-element {
    color: #999999;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;

    &.button-solid {
        color: white;
        text-align: center;

        &:hover {
            background-color: darken(#15a9e8, 5%);
            color: white;
        }
    }

    &:hover {
        color: #333333;
        transition: all 0.3s ease;
    }

    @media (min-width: $lg) {
        margin-left: 20px;
    }
    @media (min-width: $xl) {
        margin-left: 50px;
    }
    @media (max-width: $lg) {
        display: block;
        margin: 20px 0;
    }
}

#header-navigation-homepage-element {
    display: block;
    @media (min-width: $lg) {
        display: none;
    }
}

.ghost-button {
    display: inline-block;
    border: 2px solid white;
    padding: 6px 22px;
    border-radius: 3px;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        background-color: rgba(#000, 0.05);
        transition: all 0.3s ease;
        padding-bottom: 6px;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Header
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Exclamation Box
//****************************************************************************/
.exclamation-box-container {
    text-align: center;
    position: relative;
    color: white;
    padding: 50px 50px;
    background: url('/assets/images/blue-wire-art.svg') no-repeat;
    background-size: cover;
    border-radius: 5px;
    box-shadow:
        0 3px 8px rgba(#000, 0.07),
        0 9px 18px rgba(#000, 0.06);
}

.exclamation-box-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
}

.exclamation-box-button {
    display: inline-block;
    margin-top: 20px;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Exclamation Box
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Footer
//****************************************************************************/
#footer {
    color: #a1a1a1;
    padding-bottom: 30px;
    background: #f8f8fb;

    h6 {
        color: #333333;
        font-size: 1.1rem;
        font-weight: 600;
    }

    a {
        color: #808080;
    }

    @media (min-width: $md) {
        padding-top: 60px;
    }
}

#footer-container {
    padding-top: 30px;
}

#footer-logo-container {
    margin-bottom: 40px;
    text-align: center;
}

.footer-navigation-group {
    margin-bottom: 20px;
}

ul.footer-link-list {
    list-style-type: none;
    padding-left: 0;

    li {
        font-size: 0.9rem;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

#footer-supported-devices-icon {
    opacity: 0.4;
}

#footer-autoixpert-x {
    width: 30px;
}

#end-of-page-center-column {
    display: flex;
    align-items: center;
    justify-content: center;
}

#end-of-page-right-column {
    font-size: 0.9rem;
}

#dat-logo-footer {
    width: 24px;
    margin-right: 10px;
}

#end-of-page-container {
    padding-bottom: 10px;
}

#company-name {
    font-size: 0.9rem;
}

#dont-wait-container {
    margin-top: 20px;
    border-top: 1px solid #e1e1e1;
    padding-top: 40px;
    padding-bottom: 20px;
}

#footer-signup-heading {
    font-size: 1.2rem;
}

#footer-signup-tagline {
    font-size: 0.9rem;
    @media (max-width: $md) {
        margin-bottom: 20px;
    }
}

a#signup-section-button {
    display: inline-block;
    color: white;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Footer
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Tooltipster
//****************************************************************************/
.tooltipster-content {
    font-size: 0.9rem;
}

.tooltip_templates {
    display: none;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Tooltipster
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  IE / Edge Warning
//****************************************************************************/
#deprecated-browser-warning-container {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

#deprecated-browser-warning {
    max-width: 80vw;
    padding: 15px 30px;
    background: red;
    box-shadow: 0 4px 10px rgba(#000, 0.18);
    border-radius: 3px;
    color: white;

    a {
        color: white;
        text-decoration: underline;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END IE / Edge Warning
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Audatex Partnership Header
//****************************************************************************/
#audatex-partnership-header {
    background-color: #f8f0ea;
    color: #d9620e;
    @media (max-width: $lg) {
        margin-top: 60px;
    }
}

#audatex-partnership-header-text {
    padding: 8px 10px;
    text-align: center;
}

#audatex-partnership-header-border-bottom {
    background-image: linear-gradient(to right, #f1ad2c, #c4381c);
    height: 3px;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Audatex Partnership Header
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Newsletter
//****************************************************************************/

#newsletter-signup-heading-container {
    text-align: center;
    margin-bottom: 60px;
}

#newsletter-signup-heading {
    @media (max-width: $md) {
        font-size: 1.5rem;
    }
}

.newsletter-input {
    width: 100%;
    border-radius: 3px;
    border: none;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(#000, 0.15);
    transition: all 0.3s ease;

    &::placeholder {
        font-style: italic;
        color: #b1b1b1;
    }

    @media (max-width: $md) {
        margin-bottom: 20px;
    }
}

#newsletter-submit-button,
.primary-button {
    background-color: #15a9e8;
    border-color: #15a9e8;
    color: white;
    cursor: pointer;

    &.disabled {
        background-color: #e1e1e1;
        color: #a1a1a1;
        border-color: #a1a1a1;
        cursor: not-allowed;
    }

    &.disallowed {
        cursor: not-allowed;
    }

    &:not(.disabled):not(.disallowed):hover {
        background-color: #16a5d9;
        box-shadow:
            0 4px 8px rgba(#000, 0.15),
            0 10px 20px rgba(#000, 0.2);
        transform: translate3d(0, -2px, 0);
        transition: all 0.3s ease;
    }

    .pending-indicator {
        width: 20px;
        margin-right: 10px;
    }
}

.newsletter-data-protection-container {
    margin-top: 20px;
    text-align: center;
}

.newsletter-data-protection-info {
    display: none;
}

#newsletter-signup-response-container {
    display: none;
    text-align: center;
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Newsletter
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Cookie Notification
//****************************************************************************/
#cookie-notification {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 99;
    width: 90vw;
    max-width: 900px;
    border-radius: 5px;
    background-color: #f4f6fa;
    overflow: hidden;
    box-shadow:
        0 10px 20px rgba(black, 0.19),
        0 6px 6px rgba(black, 0.23);
    transform: translateX(-50%);
    @media (min-width: $lg) {
        flex-direction: row;
    }
}

#cookie-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 20px;
    font-size: 30px;
    background-image: linear-gradient(45deg, #15a9e8, #0adfff);
    @media (min-width: $lg) {
    }
}

#cookie-notification-text {
    padding: 20px;
    font-size: 0.9rem;
    flex-grow: 1;
    color: #a1a1a1;
    line-height: 1.2;
    @media (max-width: $lg) {
        text-align: center;
    }
}

#cookie-notification-heading {
    font-size: 1.3rem;
}

#cookie-notification-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;

    button {
        padding: 8px 16px;
    }

    @media (max-width: $lg) {
        margin-bottom: 20px;
    }
}

#cookie-notification-button {
    margin-right: 10px;

    &:not(:hover) {
        box-shadow: none;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Cookie Notification
/////////////////////////////////////////////////////////////////////////////*/

.italic {
    font-style: italic;
}

.monospace {
    font-family: monospace;
}

// Is appended with JavaScript
#hero-youtube-video-player {
    border: none;
}

//*****************************************************************************
//  Notes
//****************************************************************************/
.info-note {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    justify-content: center;
    align-items: center;
    position: relative; // Position the close icon relatively to this element.
    padding: 10px 10px 10px 15px;
    background-color: #f4f6fa;
    border-radius: 3px;

    &.warning-note {
        background-color: #fff0d6;

        mat-icon {
            flex: 0 0 auto;
            color: $font-medium-grey;
        }
    }

    &.small-note {
        font-size: 0.9rem;
    }
}

.info-note-close-icon {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.2s ease;

    &:hover {
        opacity: 1;
    }
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Notes
/////////////////////////////////////////////////////////////////////////////*/

//*****************************************************************************
//  Frosted Glass Card
//****************************************************************************/

.frosted-glass-card {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.23);
    background: rgba(white, 0.85);
    box-shadow: 0 6px 15px 0 rgba(black, 0.15);
    backdrop-filter: blur(40px);
}

/////////////////////////////////////////////////////////////////////////////*/
//  END Frosted Glass Card
/////////////////////////////////////////////////////////////////////////////*/

mat-icon.mat-icon {
    user-select: none;
    transform: rotate(0.03deg); // Trigger anti-aliasing to make small icons look sharp instead of pixelated.

    &.small-icon {
        font-size: 15px;
        width: 1rem;
        height: 1rem;
    }

    &.medium-icon {
        font-size: 19px;
        width: 19px;
        height: 19px;
    }

    &.moderate-icon {
        font-size: 21px;
        width: 21px;
        height: 21px;
    }

    &.regular-size-icon {
        font-size: 24px;
        width: 24px;
        height: 24px;
    }

    &.large-icon {
        font-size: 2rem;
        width: 2rem;
        height: 2rem;
    }

    &.toolbar-icon {
        margin: 0 5px;
        opacity: 0.5;
        transition: all 0.2s ease;

        &:not(.disabled):hover {
            cursor: pointer;
            opacity: 1;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}
