@use '@angular/material' as mat;

$dark-primary-text: #5c5c5c;
$light-primary-text: white;

/**
* Custom material color theme for autoiXpert generated from http://mcg.mbitson.com/.
*/
$axPalette: (
    50: #e3f5fc,
    100: #b9e5f8,
    200: #8ad4f4,
    300: #5bc3ef,
    400: #38b6eb,
    // 500 -> Primary color (aX blue)
    500: #15a9e8,
    600: #12a2e5,
    700: #0f98e2,
    800: #0c8fde,
    900: #067ed8,
    A100: #ffffff,
    A200: #cee8ff,
    A400: #9bd0ff,
    A700: #81c4ff,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$qXPalette: (
    50: #fff3e0,
    100: #ffe0b2,
    200: #ffcc80,
    300: #ffb74d,
    400: #ffa726,
    500: #f27e20,
    600: #fb8c00,
    700: #f57c00,
    800: #ef6c00,
    900: #e65100,
    A100: #ffd180,
    A200: #f27e20,
    A400: #ff9100,
    A700: #ff6d00,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $dark-primary-text,
        600: $dark-primary-text,
        700: $dark-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $dark-primary-text,
        A400: $dark-primary-text,
        A700: black,
    ),
);
