.tooltipster-sidetip.tooltipster-borderless .tooltipster-box {
    border: none;
    background: #1b1b1b;
    background: rgba(10, 10, 10, 0.9);
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-box {
    margin-top: 8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-box {
    margin-right: 8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-box {
    margin-left: 8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-box {
    margin-bottom: 8px;
}
.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow {
    height: 8px;
    margin-left: -8px;
    width: 16px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow {
    height: 16px;
    margin-left: 0;
    margin-top: -8px;
    width: 8px;
}
.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-background {
    display: none;
}
.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-border {
    border: 8px solid transparent;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: #1b1b1b;
    border-bottom-color: rgba(10, 10, 10, 0.9);
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow-border {
    border-left-color: #1b1b1b;
    border-left-color: rgba(10, 10, 10, 0.9);
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-border {
    border-right-color: #1b1b1b;
    border-right-color: rgba(10, 10, 10, 0.9);
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-arrow-border {
    border-top-color: #1b1b1b;
    border-top-color: rgba(10, 10, 10, 0.9);
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-uncropped {
    top: -8px;
}
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-uncropped {
    left: -8px;
}
